import React from 'react';

import { IconBasePropsWithColor } from './IconBase';

const UtilityPaymentSend: React.FC<IconBasePropsWithColor> = () => {
  return (
    <svg
      width="80"
      height="52"
      viewBox="0 0 80 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Utility Payment Send Icon"
    >
      <g clipPath="url(#clip0_166_6000)">
        <path
          d="M17.6286 39.8295C17.477 39.8424 17.3246 39.8239 17.18 39.7752C17.0355 39.7265 16.9019 39.6485 16.7869 39.5459C16.672 39.4432 16.5781 39.318 16.5106 39.1774C16.4432 39.0368 16.4037 38.8838 16.3943 38.7273L20.5714 7.92322C20.5807 7.76506 20.6208 7.61045 20.6893 7.46863C20.7578 7.32681 20.8533 7.20068 20.9701 7.09779C21.0869 6.9949 21.2226 6.91736 21.3691 6.8698C21.5157 6.82224 21.67 6.80563 21.8229 6.82097H69.64C69.7901 6.81057 69.9408 6.83087 70.0833 6.88069C70.2259 6.93051 70.3574 7.00888 70.4705 7.11129C70.5836 7.21371 70.676 7.33815 70.7423 7.47748C70.8087 7.61682 70.8477 7.76829 70.8571 7.92322L66.6629 38.5446C66.635 38.9079 66.4707 39.2456 66.2049 39.4857C65.939 39.7257 65.5928 39.8491 65.24 39.8295H17.6286Z"
          fill="white"
          stroke="#008380"
          strokeWidth="2.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.3"
          d="M36.8572 33.387H26.6915C26.6939 32.8693 26.5937 32.3565 26.3972 31.8801C26.2007 31.4037 25.9119 30.9738 25.5486 30.6166C25.1182 30.1629 24.5865 29.8249 24 29.6322L26.0515 16.9475C26.6923 17.1891 27.3875 17.233 28.0521 17.0739C28.7167 16.9148 29.3221 16.5596 29.7943 16.0516C30.1474 15.6881 30.4239 15.2534 30.6068 14.7748C30.7897 14.2961 30.8749 13.7836 30.8572 13.2694H30.8915H38.1772C39.751 11.7026 41.7615 10.6842 43.9258 10.3576H23.7086L20.0858 36.2929H41.2286C39.538 35.7621 38.0242 34.7558 36.8572 33.387Z"
          fill="#56B447"
        />
        <path
          opacity="0.3"
          d="M46.1257 10.3576C46.2572 10.3576 46.3829 10.3576 46.5086 10.4107C48.4764 10.7644 50.2804 11.7652 51.6514 13.2635H60.5714C60.5611 13.7795 60.6536 14.2922 60.8433 14.7698C61.033 15.2474 61.3159 15.6797 61.6743 16.0398C62.1217 16.5037 62.6763 16.8424 63.2857 17.0242L61.2343 29.703C60.5925 29.4617 59.8966 29.4179 59.2311 29.577C58.5656 29.736 57.9592 30.0911 57.4857 30.5989C57.1268 30.962 56.8441 31.3975 56.6553 31.8781C56.4666 32.3588 56.3758 32.8744 56.3886 33.3929H50.9257C49.6282 34.7517 48.0181 35.7494 46.2457 36.2929H63.56L67.1886 10.3576H46.1257Z"
          fill="#56B447"
        />
        <path
          d="M45.0344 13.8117C45.3388 13.8155 45.6426 13.8431 45.943 13.8943C49.863 14.5721 52.3544 19.4173 51.4972 24.7046C50.7201 29.5144 47.3944 33.1453 43.7544 33.1453C43.4535 33.1418 43.1534 33.1123 42.8572 33.0569C41.8995 32.8601 40.9983 32.4388 40.2235 31.8256C39.4487 31.2124 38.8209 30.4236 38.3887 29.5203C37.2376 27.2844 36.8549 24.7121 37.303 22.223C38.0687 17.4309 41.3944 13.8117 45.0344 13.8117ZM45.0344 12.0434C40.6115 12.0434 36.5258 16.2166 35.6001 21.9637C34.5887 28.2176 37.7144 33.9646 42.5601 34.8016C42.9546 34.8718 43.3541 34.9073 43.7544 34.9077C48.1772 34.9077 52.263 30.7345 53.1887 24.9875C54.2001 18.7335 51.0858 12.9806 46.2287 12.1259C45.8342 12.0558 45.4347 12.0203 45.0344 12.0198V12.0434Z"
          fill="#56B447"
        />
        <path
          d="M36.2344 28.9662C37.3772 32.0372 39.6629 34.3006 42.5601 34.8017C42.9546 34.8718 43.3541 34.9073 43.7544 34.9078C46.2401 34.9078 48.6172 33.5874 50.3887 31.4124C50.2799 30.1496 49.8812 28.9317 49.2253 27.8595C48.5694 26.7873 47.6754 25.8917 46.6172 25.2469C47.2116 24.9086 47.7233 24.4349 48.1135 23.8619C48.5036 23.2889 48.7619 22.6316 48.8687 21.9401C48.9986 21.3666 49.0131 20.7718 48.9115 20.1923C48.8098 19.6128 48.594 19.0609 48.2775 18.5708C47.961 18.0807 47.5504 17.6627 47.0712 17.3426C46.5919 17.0226 46.0542 16.8073 45.4911 16.71C44.928 16.6127 44.3516 16.6356 43.7974 16.7771C43.2432 16.9186 42.723 17.1758 42.2689 17.5328C41.8149 17.8898 41.4366 18.339 41.1574 18.8527C40.8782 19.3664 40.7041 19.9337 40.6458 20.5196C40.5283 21.2105 40.565 21.9199 40.7531 22.594C40.9412 23.2682 41.2758 23.8891 41.7315 24.4099C40.5301 24.6639 39.4047 25.2098 38.4487 26.0022C37.4927 26.7946 36.7336 27.8107 36.2344 28.9662Z"
          fill="#56B447"
        />
        <path
          d="M5.26855 11.0236H15.96"
          stroke="#00C1BF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.26855 15.2087H15.4343"
          stroke="#00C1BF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.26855 19.3996H14.9028"
          stroke="#00C1BF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M66.68 50.3333C72.9919 50.3333 78.1086 45.0553 78.1086 38.5445C78.1086 32.0338 72.9919 26.7557 66.68 26.7557C60.3682 26.7557 55.2515 32.0338 55.2515 38.5445C55.2515 45.0553 60.3682 50.3333 66.68 50.3333Z"
          fill="#57B520"
        />
        <path
          d="M72.4858 34.554L64.7429 42.5409L60.8743 38.5445"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_166_6000">
          <rect width="80" height="52" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UtilityPaymentSend;
