import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import { BulkPaymentModal } from './BulkPaymentModal';

import RoundedModal from 'components/Core/Modal/RoundedModal';
import Spinner from 'components/Shared/Spinner/Spinner';
import { companyInfoSelectors } from 'store/companyInfo/selectors';
import { insightSelectors } from 'store/insight/selectors';
import { insightActions } from 'store/insight/slice';
import { saleSelectors } from 'store/sale/selectors';
import { colors } from 'styles/cp';
import { UnpaidInvoice } from 'types/Insight';

interface BulkPaymentsProps {
  unpaidInvoices?: UnpaidInvoice[];
  companyLogoUrl: string | null;
  companyName: string;
  currency?: string;
}

const BulkPayments = ({
  unpaidInvoices,
  companyLogoUrl,
  companyName,
  currency,
}: BulkPaymentsProps) => {
  const dispatch = useDispatch();
  const renderModal = unpaidInvoices && unpaidInvoices.length > 0;

  return (
    <RoundedModal
      hide={() => dispatch(insightActions.updateIsBulkModalOpen(false))}
      customDesignKey="freeTextModal"
      backgroundColor={colors.offWhite}
      width="700px"
      height="70%"
      padding="0"
      closeIconPosition={{ right: '15px', top: '10px' }}
    >
      {renderModal ? (
        <BulkPaymentModal
          unpaidInvoices={unpaidInvoices}
          companyLogoUrl={companyLogoUrl}
          companyName={companyName}
          currency={currency}
        />
      ) : (
        <Fragment>
          <style jsx>{`
            .spinner-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
            }
          `}</style>
          <div className="spinner-wrapper">
            <Spinner width={100} />
          </div>
        </Fragment>
      )}
    </RoundedModal>
  );
};

function mapStateToProps(store: any): any {
  const { insight, companyInfo, sale } = store;
  return {
    unpaidInvoices: insightSelectors.unpaidInvoices(insight),
    companyLogoUrl: companyInfoSelectors.logoSelector(companyInfo),
    companyName: companyInfoSelectors.nameSelector(companyInfo),
    currency: saleSelectors.currencySelector(sale),
  };
}

export { BulkPayments };

export default injectIntl(
  // @ts-ignore
  connect(mapStateToProps, {})(BulkPayments)
);
