import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Close } from '../Icons/Icons';
import NewLabel from '../Icons/NewLabel';
import UtilityPaymentSend from '../Icons/UtilityPaymentSend';

import { LocalStorageUtil } from 'businessLogic/utils/storage';
import Card from 'components/Shared/Card/Card';
import SegmentIO from 'reporting/SegmentIO';
import { isFeatureFlagEnabled } from 'store/featureFlags/selectors';
import { saleSelectors } from 'store/sale/selectors';
import { useSelector } from 'store/utils';
import { breakpoints } from 'styles/cp';

const FF_NAME = 'cp-maip-page-message';

type MaipPageMessageProps = {
  companyName: string;
  isMobile: boolean;
};

const MaipPageMessage: React.FC<MaipPageMessageProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const isFfEnabled = useSelector(isFeatureFlagEnabled(FF_NAME));
  const achConveniencEnabled = useSelector((state) =>
    saleSelectors.achOnlineConvenienceFeeEnabledSelector(state.sale)
  );
  useEffect(() => {
    const wasShown = LocalStorageUtil.getFromStorage('cp_maip_page_message_shown');
    if (!wasShown) {
      setIsOpen(true);
      SegmentIO.sendEvent('transaction:viewed', {
        action: 'viewed',
        object: 'transaction',
        object_detail: 'invoice',
        ui_action: 'viewed',
        ui_object: 'message',
        ui_object_detail: 'Convenience_fee_CP_FTU_page_message',
        ui_access_point: 'center',
      });
    }
  }, []);

  const shouldShowPageMessage = isOpen && isFfEnabled && achConveniencEnabled;
  const handleCloseMaipPageMessage = () => {
    LocalStorageUtil.setToStorage('cp_maip_page_message_shown', true);
    setIsOpen(false);
    SegmentIO.sendEvent('transaction:engaged', {
      action: 'engaged',
      object: 'transaction',
      object_detail: 'invoice',
      ui_action: 'clicked',
      ui_object: 'message',
      ui_object_detail: 'Convenience_fee_CP_FTU_page_message',
      ui_access_point: 'center',
    });
  };

  if (!shouldShowPageMessage) {
    return null;
  }

  return (
    shouldShowPageMessage && (
      <div className="maip-page-message-wrapper">
        <div className="main-card-wrapper">
          <Card>
            <div className="main-card-container">
              {!props.isMobile && (
                <div className="maip-utility-payment-send-icon">
                  <UtilityPaymentSend />
                </div>
              )}
              <div className="main-card-content">
                <NewLabel />
                <FormattedMessage
                  id="MAIP_PAGE_MESSAGE"
                  values={{
                    companyName: props.companyName,
                  }}
                  defaultMessage={
                    'Pay {companyName} now. It’s fast, secure, and you won’t have to worry about it later.'
                  }
                />
              </div>
              <div
                className="close-maip-page-message"
                onClick={handleCloseMaipPageMessage}
                aria-label="Close Maip Page Message"
              >
                <Close />
              </div>
            </div>
          </Card>
        </div>
        <style jsx>{`
          .maip-page-message-wrapper {
            @media screen and (max-width: ${breakpoints.md}) {
              padding: 43px 16px 0;
            }

            .main-card-wrapper {
              display: flex;
              padding: 20px;
              align-items: flex-start;
              align-self: center;
              gap: 12px;
              border-radius: 16px;
              border: 1px solid #d4d7dc;
              background: #ffffff;

              .main-card-container {
                display: flex;
                gap: 12px;
              }

              .main-card-content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 4px;
                flex: 1 0 0;
              }

              .close-maip-page-message {
                cursor: pointer;
              }

              @media screen and (max-width: ${breakpoints.md}) {
                border-radius: 4px;
                .maip-utility-payment-send-icon {
                  display: none;
                }
              }
            }
          }
        `}</style>
      </div>
    )
  );
};

export default MaipPageMessage;
