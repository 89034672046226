import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { BulkModalContent } from './BulkModalContent';
import { BulkModalFooter } from './BulkModalFooter';

import Checkbox from 'components/Shared/Inputs/Checkbox';
import useSessionStorage from 'hooks/useSessionStorage';
import { insightActions } from 'store/insight/slice';
import { paymentAmountChange } from 'store/payment/slice';
import { colors, fontSize } from 'styles/cp';
import { UnpaidInvoice } from 'types/Insight';

interface BulkPaymentModalProps {
  unpaidInvoices: UnpaidInvoice[];
  companyLogoUrl: string | null;
  companyName: string;
  currency?: string;
}

export const BulkPaymentModal = ({
  companyLogoUrl,
  companyName,
  currency,
  unpaidInvoices,
}: BulkPaymentModalProps) => {
  const [bulkInvoices, setBulkInvoices] = useState<UnpaidInvoice[]>(() =>
    unpaidInvoices?.map((invoice) => ({ ...invoice, selected: invoice.defaultInvoice }))
  );
  const [, setBulkPayments] = useSessionStorage<{ invoices: UnpaidInvoice[]; totalAmount: number }>(
    'bulkPayments',
    { invoices: [], totalAmount: 0 }
  );

  const isAllChecked = !bulkInvoices?.some((invoice) => !invoice.selected);
  const selectedInvoices = useMemo(
    () => bulkInvoices.filter((invoice) => invoice.selected),
    [bulkInvoices]
  );

  const totalBalance = useMemo(
    () => selectedInvoices.reduce((total, invoice) => total + invoice.balance, 0),
    [selectedInvoices]
  );

  const handleSelectAllCheck = useCallback(() => {
    setBulkInvoices((prev) =>
      prev?.map((invoice) => ({
        ...invoice,
        selected: isAllChecked && !invoice.defaultInvoice ? false : true,
      }))
    );
  }, [isAllChecked, setBulkInvoices]);

  const dispatch = useDispatch();
  const handleBulkCheckout = useCallback(() => {
    const isMoreTheOneInvoiceSelected = Boolean(
      selectedInvoices.length !== 1 || !selectedInvoices[0].defaultInvoice
    );
    setBulkPayments({ invoices: selectedInvoices, totalAmount: totalBalance });
    dispatch(insightActions.updateIsBulkModalOpen(false));
    dispatch(insightActions.updateIsBulkCheckout(isMoreTheOneInvoiceSelected));
    // @ts-ignore
    dispatch(paymentAmountChange(totalBalance));
  }, [setBulkPayments, selectedInvoices, totalBalance]);

  return (
    <div className="bulk-modal-wrapper">
      <div className="modal-content-wrapper">
        <div className="flex-left title-wrapper">
          {companyLogoUrl && <img src={companyLogoUrl} width={49} height={49} />}
          <div>
            <div className="bold">
              <FormattedMessage
                id="BULK_PAYMENTS_UNPAID_INVOICES"
                defaultMessage={`${bulkInvoices.length} unpaid invoices from ${companyName}`}
                values={{ bulkInvoicesLength: bulkInvoices.length, companyName }}
              />
            </div>
            <div className="regular-text">
              <FormattedMessage
                id="BULK_PAYMENTS_SELECT_INVOICES"
                defaultMessage="Select invoices to pay"
              />
            </div>
          </div>
        </div>
        <div className="flex-left select-all-wrapper">
          <Checkbox
            checked={isAllChecked}
            onChange={handleSelectAllCheck}
            disabled={false}
            id="selectAllCheckbox"
          />
          <div className="small-text">
            <label htmlFor="selectAllCheckbox">
              <FormattedMessage id="PAYFLOW_OPEN_INVOICES_SELECT_ALL" defaultMessage="Select All" />
            </label>
          </div>
        </div>
        <BulkModalContent
          bulkInvoices={bulkInvoices}
          setBulkInvoices={setBulkInvoices}
          currency={currency}
        />
      </div>
      {totalBalance && selectedInvoices && (
        <BulkModalFooter
          currency={currency}
          totalBalance={totalBalance}
          selectedInvoicesLength={selectedInvoices.length}
          handleBulkCheckout={handleBulkCheckout}
        />
      )}
      <style jsx>{`
        .bulk-modal-wrapper {
          height: 100%;
        }
        .flex-left {
          display: flex;
          text-align: left;
        }
        .modal-content-wrapper {
          overflow-y: auto;
          overflow-x: hidden;
          height: 90%;
        }
        .title-wrapper {
          gap: 10px;
          color: ${colors.gray};
          padding-top: 24px;
          padding-left: 24px;
          margin-right: 44px;
        }
        .regular-text {
          font-size: ${fontSize.xs};
        }
        .small-text {
          font-size: ${fontSize.xxs};
          label {
            cursor: pointer;
          }
        }
        .bold {
          font-family: AvenirNextforINTUIT-Demi;
        }
        .select-all-wrapper {
          padding: 20px 24px 12px;
          margin-left: 10px;
          gap: 12px;
          align-items: center;
          background: ${colors.offWhite};
          position: sticky;
          top: 0;
          z-index: 2;
          border-top-right-radius: 12px;
          border-top-left-radius: 12px;
        }
      `}</style>
    </div>
  );
};
