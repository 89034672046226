import React from 'react';

import { BulkInvoiceRow } from './BulkInvoiceRow';

import { colors, fontSize } from 'styles/cp';
import { UnpaidInvoice } from 'types/Insight';

interface BulkModalRowsContentProps {
  invoices: UnpaidInvoice[];
  setBulkInvoices: React.Dispatch<React.SetStateAction<UnpaidInvoice[]>>;
  title: React.ReactNode;
  currency?: string;
}

export const BulkModalRowsContent = ({
  invoices,
  setBulkInvoices,
  title,
  currency,
}: BulkModalRowsContentProps) => {
  return (
    <>
      <div className="flex-left category-title small-text bold">{title}</div>
      {invoices.map((invoice) => (
        <BulkInvoiceRow
          key={invoice.docNumber}
          invoiceData={invoice}
          setBulkInvoices={setBulkInvoices}
          currency={currency}
        />
      ))}
      <style jsx>{`
        .flex-left {
          display: flex;
          text-align: left;
        }
        .category-title {
          color: ${colors.gray02};
          padding-bottom: 10px;
          background: ${colors.offWhite};
          position: sticky;
          top: 52px;
          z-index: 2;
        }
        .small-text {
          font-size: ${fontSize.xxs};
        }
        .bold {
          font-family: AvenirNextforINTUIT-Demi;
        }
      `}</style>
    </>
  );
};
