import React from 'react';
import { FormattedMessage } from 'react-intl';

import { BulkInvoiceRow } from './BulkInvoiceRow';
import { BulkModalRowsContent } from './BulkModalRowsContent';

import { colors, fontSize } from 'styles/cp';
import { UnpaidInvoice } from 'types/Insight';

interface BulkModalContentProps {
  bulkInvoices: UnpaidInvoice[];
  setBulkInvoices: React.Dispatch<React.SetStateAction<UnpaidInvoice[]>>;
  currency?: string;
}

export const BulkModalContent = ({
  bulkInvoices,
  setBulkInvoices,
  currency,
}: BulkModalContentProps) => {
  const currentInvoice = bulkInvoices.find((invoice) => invoice.defaultInvoice);

  const overDueInvoices = bulkInvoices.filter(
    (invoice) => invoice.overDue && !invoice.defaultInvoice
  );
  const dueInvoices = bulkInvoices.filter((invoice) => !invoice.overDue && !invoice.defaultInvoice);

  return (
    <div className="bulk-modal-content-wrapper">
      <div className="flex-left category-title small-text bold">
        <FormattedMessage id="BULK_PAYMENTS_CURRENT_INVOICE" defaultMessage="Current invoice" />
      </div>
      {currentInvoice && (
        <BulkInvoiceRow
          checked
          disabled
          invoiceData={currentInvoice}
          setBulkInvoices={setBulkInvoices}
          currency={currency}
        />
      )}
      {overDueInvoices.length > 0 && (
        <BulkModalRowsContent
          invoices={overDueInvoices}
          title={
            <FormattedMessage
              id="BULK_PAYMENTS_OVERUDE_INVOICES"
              defaultMessage="Overdue invoices"
            />
          }
          setBulkInvoices={setBulkInvoices}
          currency={currency}
        />
      )}
      {dueInvoices.length > 0 && (
        <BulkModalRowsContent
          invoices={dueInvoices}
          title={<FormattedMessage id="BULK_PAYMENTS_INVOICES_DUE" defaultMessage="Invoices due" />}
          setBulkInvoices={setBulkInvoices}
          currency={currency}
        />
      )}
      <style jsx>
        {`
          .bulk-modal-content-wrapper {
            padding: 0 24px;
            margin-bottom: 24px;
          }
          .flex-left {
            display: flex;
            text-align: left;
          }
          .category-title {
            color: ${colors.gray02};
            padding-bottom: 10px;
            background: ${colors.offWhite};
            position: sticky;
            top: 52px;
            z-index: 2;
          }
          .small-text {
            font-size: ${fontSize.xxs};
          }
          .bold {
            font-family: AvenirNextforINTUIT-Demi;
          }
        `}
      </style>
    </div>
  );
};
