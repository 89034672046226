import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { CPStore } from 'apps/CPApp';
import {
  shouldShowSchedulePayDisabledMessage,
  shouldShowVenmoInegratedBrowswerMessage,
} from 'businessLogic/Payment/Paypal';
import { isMAIPasPDF } from 'businessLogic/Payment/helpers';
import BulkPayments from 'components/BulkPayments/BulkPayments';
import ScheduleAndAutopayMobileCard from 'components/Core/Autopay-schedule/ScheduleAndAutoPay/ScheduleAndAutopayMobileCard/ScheduleAndAutopayMobileCard';
import MainFooter from 'components/Core/Footer/MainFooterComponent/MainFooter';
import InvoiceMoreDetails from 'components/Core/Invoice/MoreDetails/InvoiceMoreDetails';
import InvoicePreviewCard from 'components/Core/Invoice/Preview/Card/InvoicePreviewCard';
import UnpayableCard from 'components/Core/NonPayEnabled/UnpayableCard/UnpayableCard';
import OpenInvoicesNotice from 'components/Core/OpenInvoices/Notice/OpenInvoicesNotice';
import Payment from 'components/Core/Payment/Payment';
import PaymentInstructions from 'components/Core/SidePanel/PaymentInstructions/PaymentInstructions';
import SidePanelWrapper from 'components/Core/SidePanel/Wrapper/SidePanelWrapper';
import Card from 'components/Shared/Card/Card';
import { FeedbackLink } from 'components/Shared/Feedback/Feedback';
import PaymentAmountInput from 'components/Shared/Inputs/PaymentAmountInput';
import MessageCard from 'components/Shared/MessageCard/MessageCard';
import MobileInvoiceHeader from 'components/Shared/MobileInvoiceHeader/MobileInvoiceHeader';
import MaipPageMessage from 'components/Shared/PageMessages/MaipPageMessage';
import MaipExitSurvey from 'components/Shared/QualtricsSurveys/Survey/MaipExitSurvey/MaipExitSurvey';
import Separator from 'components/Shared/Separator/Separator';
import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
import WavefrontReporter from 'reporting/wavefront/WavefrontReporter';
import deviceInfo from 'server/helpers/deviceInfo';
import { getPaymentErrorMessage } from 'shared/pagesHelpers';
import { signIn } from 'store/auth/slice';
import { companyInfoSelectors } from 'store/companyInfo/selectors';
import { insightSelectors } from 'store/insight/selectors';
import { downloadPDFDocument, fetchPDFDocument } from 'store/invoiceDocument/slice';
import { modalActions } from 'store/modal/slice';
import { isPaymentError } from 'store/payment/helpers';
import { getUsedPaymentMethod, getUsedPaymentType } from 'store/payment/selectors';
import { paymentAmountChange, paymentAmountReset, onPayButtonClicked } from 'store/payment/slice';
import { saleSelectors } from 'store/sale/selectors';
import { walletSelectors } from 'store/wallet/selectors';
import { breakpoints, colors, fontSize } from 'styles/cp';
import { TXN_MAP } from 'types/constants';
const splunkReporter = SplunkReporter.getInstance();
const logger = 'pages/payable.page';

const wavefrontReporter = WavefrontReporter.getInstance();
let SuccessScreen;
import('components/Core/SuccessScreen/SuccessScreen/SuccessScreen').then(
  ({ default: SuccessScreenModule }) => {
    SuccessScreen = SuccessScreenModule;
  }
);

class Payable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  static async getInitialProps() {
    return {
      pageProps: {},
    };
  }

  componentDidMount() {
    const {
      payment: { payPalProcessor, isPayPalCommerceInvoice },
      token,
      invoiceDocument: { fetchPDFStatus },
      fetchPDFDocument,
    } = this.props;
    if (fetchPDFStatus !== TXN_MAP.STATUS.SUCCESS) {
      fetchPDFDocument(token);
    }
    this.setState({
      isTablet: deviceInfo.isTablet(
        (typeof window === 'object' && window.navigator && window.navigator.userAgent) || ''
      ),
    });
    this.setState({
      isMobile: deviceInfo.isMobile(
        (typeof window === 'object' && window.navigator && window.navigator.userAgent) || ''
      ),
    });
    splunkReporter.contextual({
      logInfo: { logLevel: 'info', logger },
      event: 'viewSale',
      action: 'render',
      activityInfo: {
        screen: 'payable',
        payPalProcessor,
        isPayPalCommerceInvoice,
      },
    });
  }

  componentDidUpdate(prevProps) {
    const { payment, wallet } = this.props;

    if (
      isPaymentError({
        prevPayment: prevProps.payment,
        currentPayment: payment,
      })
    ) {
      wavefrontReporter.trackPayment({
        paymentMethod: getUsedPaymentMethod({ payment: payment }),
        status: 'failed',
        subFlow: getUsedPaymentType({ payment: payment }),
        isPaymentUsingWallet: walletSelectors.isPaymentUsingWallet({ wallet, payment }),
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    const {
      payment: { payPalProcessor, isPayPalCommerceInvoice },
    } = this.props;

    splunkReporter.contextual({
      logInfo: { logLevel: 'error', logger },
      event: 'viewSale',
      action: 'render',
      activityInfo: {
        screen: 'payable',
        payPalProcessor,
        isPayPalCommerceInvoice,
      },
      error: {
        message: error?.message,
        stack: errorInfo,
      },
    });
  }
  renderInfoMessageIfAny() {
    const {
      payment: { paymentMethodType },
      auth: { isUserSignedIn },
      wallet: { enabledPaymentMethods = [] } = {},
      isMaipASPDFEnabled,
    } = this.props;
    if (shouldShowVenmoInegratedBrowswerMessage({ paymentMethodType })) {
      return {
        text: 'PAYNOW_VENMO_INTEGRATED_BROWSWER',
        status: 'info',
      };
    }
    if (
      shouldShowSchedulePayDisabledMessage({
        enabledPaymentMethods,
        isUserSignedIn,
        paymentMethodType,
      }) &&
      !isMaipASPDFEnabled
    ) {
      return {
        text: 'PAYNOW_SCHEDULED_PAYMENT_NOT_ENABLED',
        status: 'info',
      };
    }
  }
  renderErrorIfAny() {
    const {
      featureFlags,
      payment: {
        paymentError,
        paymentStatus,
        paymentMethodType,
        payPalProcessor,
        isDateScheduled,
        isAutoPayOn,
      },
      companyName,
    } = this.props;
    return getPaymentErrorMessage({
      paymentError,
      paymentStatus,
      companyName,
      featureFlags,
      logger,
      paymentMethodType,
      payPalProcessor,
      isDateScheduled,
      isAutoPayOn,
    });
  }

  isPaymentsBlocked() {
    const { featureFlags } = this.props;
    return featureFlags && featureFlags['block-payments'];
  }

  render() {
    const {
      payment,
      companyEmail,
      companyPhone,
      companyWebAddr,
      companyAddress,
      number,
      dueDate,
      amount,
      balanceAmount,
      currency,
      isPartiallyPaid,
      companyName,
      token,
      isFullyPaid,
      isPayable,
      companyLogoUrl,
      companyLocale,
      invoiceDocument: { fetchPDFStatus, PDFDocumentBlobUrl },
      config,
      auth: { isUserSignedIn },
      shouldShowSchedulePay,
      shouldShowAutoPay,
      customerOpenInvoices,
      isBulkModalOpen,
      featureFlags,
      signIn,
      wallet: { enabledPaymentMethods = [] } = {},
      sale,
      companyInfo,
      insight,
      ixp,
      isMaipASPDFEnabled,
      achOnlineConvenienceFeeEnabled,
      achOnlineConvenienceFeeAmount,
      achOnlineConvenienceFeeAmountPaid,
    } = this.props;

    const {
      lastSuccessfulPaymentResponse,
      gratuityValue,
      lastSuccessfulSchedulePaymentResponse,
      paymentMethodType,
    } = payment;
    let pdfUrl = PDFDocumentBlobUrl;
    const country = companyLocale.split('_')[1];
    const fullPaymentMsg = (
      <span className="payment-full">
        <FormattedMessage id="PAYNOW_PAYMENT_FULL" defaultMessage="Paid in full" />
      </span>
    );
    const isSpOrAp =
      (shouldShowAutoPay || shouldShowSchedulePay) &&
      !['paypal_ppaam', 'venmo'].includes(paymentMethodType) &&
      !isMaipASPDFEnabled;
    // LastSuccessfulPaymentResponse should be an object that contain the necessary information for the success screen
    // LastSuccessfulPaymentResponse would be set to 'back_from_success' if we trigger hide success screen action
    if (
      (lastSuccessfulPaymentResponse && lastSuccessfulPaymentResponse !== 'back_from_success') ||
      (lastSuccessfulSchedulePaymentResponse && SuccessScreen)
    ) {
      return <SuccessScreen />;
    }
    let infoProps = this.renderInfoMessageIfAny();

    const { receivable: { allowOnlinePPAAMPayment } = {} } = sale;

    const isBulkPaymentsFF = featureFlags && featureFlags['cp-bulk-payments'];

    return (
      <Fragment>
        <MaipExitSurvey
          src={'https://customersurveys.intuit.com/jfe/form/SV_4ZoleQ07JNqQdmu'}
          onHide={() => this.setState({ shouldShowUnpayableSurvey: false })}
        />
        <div className="main-wrapper">
          <MaipPageMessage companyName={companyName} isMobile={this.state.isMobile} />
          {isBulkPaymentsFF &&
            isBulkModalOpen && ( // TODO: change this condition
              <BulkPayments />
            )}
          <SidePanelWrapper
            isPaymentInstruction={true}
            companyDetails={{ companyAddress, companyEmail, companyPhone, companyWebAddr, number }}
          >
            {customerOpenInvoices && (
              <Fragment>
                <OpenInvoicesNotice invoices={customerOpenInvoices} />
                <Separator height={10} />
              </Fragment>
            )}

            <InvoicePreviewCard
              invoiceDocument={this.props.invoiceDocument}
              gratuityValue={gratuityValue}
              companyName={companyName}
              companyLogoUrl={companyLogoUrl}
              number={number}
              dueDate={dueDate}
              amount={amount}
              balanceAmount={balanceAmount}
              currency={currency}
              token={token}
              isPartiallyPaid={isPartiallyPaid}
              isPayable={isPayable}
              isFullyPaid={isFullyPaid}
              showModal={this.props.showModal}
              downloadPDFDocument={this.props.downloadPDFDocument}
              isTablet={this.state.isTablet}
              totalAmount={this.props.payment.amount}
              pdfUrl={pdfUrl}
              lineItems={this.props.lineItems}
              taxAmount={this.props.taxAmount}
              ixp={ixp}
              featureFlags={featureFlags}
              achOnlineConvenienceFeeEnabled={achOnlineConvenienceFeeEnabled}
              achOnlineConvenienceFeeAmount={achOnlineConvenienceFeeAmount}
              achOnlineConvenienceFeeAmountPaid={achOnlineConvenienceFeeAmountPaid}
            />
          </SidePanelWrapper>

          <div
            className={`main flex-column ${
              lastSuccessfulPaymentResponse === 'back_from_success' ? 'fade-in' : ''
            }`}
          >
            {this.renderErrorIfAny()}
            {infoProps && <MessageCard {...infoProps} />}

            {isSpOrAp && (
              <div className="schedule-pay-card">
                <ScheduleAndAutopayMobileCard
                  isAutoPay={shouldShowAutoPay}
                  isLoggedIn={isUserSignedIn}
                  signIn={signIn}
                />
              </div>
            )}
            {allowOnlinePPAAMPayment && enabledPaymentMethods.length === 0 ? (
              <UnpayableCard
                sale={sale}
                companyInfo={companyInfo}
                insight={insight}
                config={config}
                fetchPDFStatus={fetchPDFStatus}
                pdfUrl={pdfUrl}
                showModal={this.props.showModal}
                balanceAmount={balanceAmount}
                featureFlags={featureFlags}
                allowOnlinePPAAMPayment={allowOnlinePPAAMPayment}
                paymentDetailsMessage={
                  <FormattedMessage
                    id="PAYNOW_PAYMENT_NOT_ENABLED_FOR_PAYPAL"
                    values={{
                      0: 'PayPal',
                      1: companyName,
                    }}
                    defaultMessage={`PayPal is not enabled for your location or device at this time. Please contact ${companyName}`}
                  />
                }
              />
            ) : (
              <Card>
                <MobileInvoiceHeader
                  featureFlags={featureFlags}
                  ixp={ixp}
                  companyLogoUrl={companyLogoUrl}
                  companyName={companyName}
                />

                <InvoiceMoreDetails
                  featureFlags={this.props.featureFlags}
                  showModal={this.props.showModal}
                  companyAddress={companyAddress}
                  companyEmail={companyEmail}
                  companyPhone={companyPhone}
                  companyWebAddr={companyWebAddr}
                  number={number}
                  dueDate={dueDate}
                  amount={amount}
                  balanceAmount={balanceAmount}
                  currency={currency}
                  isPartiallyPaid={isPartiallyPaid}
                  totalAmount={this.props.payment.amount}
                  pdfUrl={pdfUrl}
                  fetchPDFStatus={fetchPDFStatus}
                  achOnlineConvenienceFeeAmount={achOnlineConvenienceFeeAmount}
                  achOnlineConvenienceFeeAmountPaid={achOnlineConvenienceFeeAmountPaid}
                  achOnlineConvenienceFeeEnabled={achOnlineConvenienceFeeEnabled}
                  lineItems={this.props.lineItems}
                  taxAmount={this.props.taxAmount}
                />

                <div className="main-card-wrapper bottom">
                  <div className="payment-amount-wrapper">
                    {isFullyPaid && fullPaymentMsg}
                    <span className="payment-amount-label">
                      <FormattedMessage
                        id="INVOICE_SUMMARY_MOBILE_PAYMENT_AMOUNT"
                        defaultMessage="payment amount"
                      />
                    </span>

                    <PaymentAmountInput
                      paymentAmountChange={this.props.paymentAmountChange}
                      paymentAmountReset={this.props.paymentAmountReset}
                      intl={this.props.intl}
                      currency={currency}
                      balanceAmount={balanceAmount}
                      openInvoices={[]}
                      payment={this.props.payment}
                      featureFlags={this.props.featureFlags}
                      region={companyInfo.region}
                      achOnlineConvenienceFeeEnabled={achOnlineConvenienceFeeEnabled}
                      achOnlineConvenienceFeeAmount={achOnlineConvenienceFeeAmount}
                    />
                  </div>
                  <Separator height={25} />
                  <Payment />
                </div>
                <MainFooter companyLocale={companyLocale} isPayable={isPayable} />
                <Separator height={20} />
              </Card>
            )}

            {country && country.toLowerCase() === 'us' && (
              <span className="feedback-w">
                <FeedbackLink />
              </span>
            )}
          </div>
        </div>

        <div className="merchant-msg-mobile">
          <Separator height={20} />
          <PaymentInstructions />
          <Separator height={20} />
        </div>

        {this.props.payment.paymentMethodType !== null ? (
          <div className="mobile-button-space">
            <Separator height={80} />
          </div>
        ) : null}

        {fetchPDFStatus === TXN_MAP.STATUS.SUCCESS && pdfUrl && (
          <iframe id="hiddenPDFIframe" src={pdfUrl} style={{ display: 'none' }} />
        )}

        <style jsx>{`
          .main {
            width: 65%;
            padding: 15px 0;
            word-break: break-word;
            @media screen and (max-width: ${breakpoints.md}) {
              padding: ${companyLogoUrl && !isSpOrAp ? '43px' : '10px'} 16px 0;
              max-width: 100%;
              width: 100%;
            }
            @media screen and (min-width: ${breakpoints.md}) {
              .mobile-time-left-to-pay {
                display: none;
              }
            }
            .schedule-pay-card {
              display: none;
              @media screen and (max-width: ${breakpoints.md}) {
                display: block;
                margin-bottom: 24px;
              }
            }
            .main-card-wrapper {
              padding: 15px 66px 0;
              max-width: 882px;

              @media screen and (max-width: ${breakpoints.md}) {
                padding: 20px 20px 0;
                &.bottom {
                  padding: 0 20px;
                }
              }
              .payment-amount-wrapper {
                text-align: left;
                margin-top: 10px;
                @media screen and (max-width: ${breakpoints.md}) {
                }
              }
              :global(.payment-full) {
                font-size: ${fontSize.xl};
                height: 35px;
                line-height: 0.53;
                font-style: normal;
                font-stretch: normal;
                letter-spacing: normal;
                color: ${colors.green};
                margin-top: 67px;
                display: block;
              }
              .payment-amount-label {
                font-family: AvenirNextforINTUIT-Demi;
                font-size: ${fontSize.xxs};
                color: ${colors.whiteGray};
                text-transform: uppercase;
                display: inline-block;
              }
              .payment-label {
                display: inline-block;
                margin-left: 5px;
              }
              .payment-amount {
                font-family: AvenirNextforINTUIT-Demi;
                font-size: ${fontSize.xl};
                line-height: 37px;
                color: ${colors.darkGray};
                text-transform: uppercase;
                display: block;
              }
              :global(.payment-confirmation-email-msg) {
                font-size: ${fontSize.sm};
                font-style: normal;
                font-stretch: normal;
                line-height: 1.38;
                letter-spacing: normal;
                text-align: center;
                display: block;
                color: ${colors.darkGray};
                margin-top: 50px;
              }
              :global(.contact-message) {
                font-size: ${fontSize.md};
                color: ${colors.darkGray};
                display: block;
                margin-top: 15px;
                margin-bottom: 25px;
                text-align: center;
                line-height: 1.3;
                @media screen and (max-width: ${breakpoints.md}) {
                  margin-bottom: 38px;
                }
              }
            }
            .feedback-w {
              @media not screen and (max-width: ${breakpoints.md}) {
                display: none;
              }
            }
          }
          .merchant-msg-mobile {
            display: none;
            @media screen and (max-width: ${breakpoints.md}) {
              padding-top: 17px;
              display: block;
            }
          }
          .mobile-button-space {
            @media not screen and (max-width: ${breakpoints.md}) {
              display: none;
            }
          }
        `}</style>
      </Fragment>
    );
  }
}

function mapStateToProps(store) {
  const {
    invoiceDocument,
    companyInfo,
    sale,
    wallet,
    insight,
    config,
    payment,
    featureFlags,
    auth,
    ixp,
  } = store;
  return {
    companyEmail: companyInfoSelectors.emailSelector(companyInfo),
    companyPhone: companyInfoSelectors.phoneSelector(companyInfo),
    companyWebAddr: companyInfoSelectors.webAddrSelector(companyInfo),
    companyAddress: companyInfoSelectors.addressSelector(companyInfo),
    companyName: companyInfoSelectors.nameSelector(companyInfo),
    companyLogoUrl: companyInfoSelectors.logoSelector(companyInfo),
    companyLocale: companyInfoSelectors.localeSelector(companyInfo),
    number: saleSelectors.referenceNumberSelector(sale),
    dueDate: saleSelectors.dueDateSelector(sale),
    currency: saleSelectors.currencySelector(sale),
    amount: saleSelectors.amountSelector(sale),
    balanceAmount: saleSelectors.balanceSelector(sale),
    lineItems: saleSelectors.lineItemsSelector(sale),
    taxAmount: saleSelectors.taxAmountSelector(sale),
    isPartiallyPaid: insightSelectors.isPartiallyPaidSelector(insight),
    token: insightSelectors.tokenSelector(insight),
    isPayable: insightSelectors.isPayableSelector(insight),
    isFullyPaid: insightSelectors.isFullyPaidSelector(insight),
    shouldShowSchedulePay: insightSelectors.shouldShowSchedulePaySelector(insight),
    shouldShowAutoPay: insightSelectors.shouldShowAutopaySelector(insight),
    isPayEnabled: insightSelectors.isPayEnabledSelector(insight),
    isBulkModalOpen: insightSelectors.isBulkModalOpen(insight),

    customerOpenInvoices: saleSelectors.customerOpenInvoicesSelector(sale),
    saleType: saleSelectors.typeSelector(sale),
    paymentDetailsMessage: saleSelectors.paymentDetailsMessageSelector(sale),
    isMaipASPDFEnabled: isMAIPasPDF({ sale, featureFlags }),
    achOnlineConvenienceFeeAmount: saleSelectors.achOnlineConvenienceFeeAmountSelector(sale),
    achOnlineConvenienceFeeAmountPaid:
      saleSelectors.achOnlineConvenienceFeeAmountPaidSelector(sale),
    achOnlineConvenienceFeeEnabled: saleSelectors.achOnlineConvenienceFeeEnabledSelector(sale),
    sale,
    ixp,
    companyInfo,
    insight,
    invoiceDocument,
    config,
    payment,
    wallet,
    featureFlags,
    auth,
  };
}

const with_injectIntl = injectIntl(Payable);
const with_connectToRedux = connect(mapStateToProps, {
  showModal: modalActions.show,
  fetchPDFDocument,
  downloadPDFDocument,
  paymentAmountChange,
  paymentAmountReset,
  onPayButtonClicked,
  signIn,
})(with_injectIntl);
const with_CPStore = CPStore(with_connectToRedux);

export default with_CPStore;
