import React, { Fragment, useState, useEffect } from 'react';

import QualtricsModal from 'components/Shared/QualtricsSurveys/QualtricsModal/QualtricsModal';
import SegmentIO from 'reporting/SegmentIO';
import { isFeatureFlagEnabled } from 'store/featureFlags/selectors';
import { insightSelectors } from 'store/insight/selectors';
import { saleSelectors } from 'store/sale/selectors';
import { useSelector } from 'store/utils';

export interface Props {}

const FF_NAME = 'maip-exit-survey-enabled';

/**
 * Invoice for testing https://connect-e2e.api.intuit.com/t/scs-v1-d4f51592be5b451194ff94debff97d8445da86ed830f497d91f3d032809daf8e406a1770aac4462290d7c3953ba419a1
 */
const MaipExitSurvey: React.FC<Props> = () => {
  const isFfEnabled = useSelector(isFeatureFlagEnabled(FF_NAME));
  useEffect(() => {
    if (!isFfEnabled) {
      return; // FF is disabled, IXP should not fetch
    }
  }, [isFfEnabled]);

  const [isOpen, setIsOpen] = useState(false);
  const payorSegmentation = useSelector((state) => state.insight.payorSegmentation);
  const invoiceAmount = useSelector((state) => saleSelectors.amountSelector(state.sale));
  const saleID = useSelector((state) => saleSelectors.referenceNumberSelector(state.sale));
  const merchantId = useSelector((state) => insightSelectors.companyIdSelector(state.insight));
  const AchConveniencEnabled = useSelector((state) =>
    saleSelectors.achOnlineConvenienceFeeEnabledSelector(state.sale)
  );
  const balanceAmount = useSelector((state) => saleSelectors.balanceSelector(state.sale));
  const isBusiness = payorSegmentation.isBusiness;
  const FTU = payorSegmentation.isFirstTimeInCP;
  const hasEverPaid = payorSegmentation.previousPaymentDate !== null;

  useEffect(() => {
    if (!(isFfEnabled && AchConveniencEnabled && balanceAmount > 0)) {
      return;
    }
    // 10 pixels from the top of the viewport
    const handleMouseEnter = (e: MouseEvent) => {
      if (e.clientY <= 10) {
        const wasShown = localStorage.getItem('maip_exit_survey_shown') === 'true';
        if (!wasShown) {
          localStorage.setItem('maip_exit_survey_shown', 'true');
          setIsOpen(true);
          SegmentIO.sendEvent('survey:viewed', {
            action: 'viewed',
            object: 'maip_exit_survey',
            ui_action: 'loaded',
            ui_object: 'modal',
            ui_object_detail: 'maip_exit_survey',
            ui_access_point: 'modal',
          });
        }
      }
    };
    setTimeout(() => {
      window.addEventListener('mousemove', handleMouseEnter);

      return () => {
        window.removeEventListener('mousemove', handleMouseEnter);
      };
    }, 3000);
  }, [isFfEnabled]);

  function hide() {
    setIsOpen(false);
    SegmentIO.sendEvent('survey:engaged', {
      action: 'viewed',
      object: 'maip_exit_survey',
      ui_action: 'loaded',
      ui_object: 'modal',
      ui_object_detail: 'close_maip_exit_survey',
      ui_access_point: 'modal',
    });
  }

  if (!isFfEnabled) {
    return <div data-testid="maip-exit-experiment-hidden" />;
  }

  return (
    <Fragment>
      {isOpen && (
        <QualtricsModal
          src={`https://customersurveys.intuit.com/jfe/form/SV_eqTjG1iuKiZrcp0?FTU=${FTU}&hasEverPaid=${hasEverPaid}&isBusiness=${isBusiness}&invoiceAmount=${invoiceAmount}&companyID=${merchantId}&saleID=${saleID}`}
          onHide={hide}
          height="700px"
        />
      )}
    </Fragment>
  );
};

export default MaipExitSurvey;
