import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import Button from 'components/Shared/Button';
import Lock from 'components/Shared/Icons/Lock';
import { colors } from 'styles/cp';

interface BulkModalFooterProps {
  totalBalance: number;
  selectedInvoicesLength: number;
  currency?: string;
  handleBulkCheckout: () => void;
}

export const BulkModalFooter = ({
  totalBalance,
  selectedInvoicesLength,
  currency,
  handleBulkCheckout,
}: BulkModalFooterProps) => (
  <div className="sticky-footer-wrapper">
    <div className="footer-content">
      <div className="footer-text">
        <span>
          <FormattedMessage id="INVOICE_TOTAL" defaultMessage="Total" />{' '}
          <FormattedNumber
            style="currency"
            currency={currency}
            value={totalBalance}
            minimumFractionDigits={0}
            maximumFractionDigits={2}
          />
        </span>
        <span className="total-second-part">
          <FormattedMessage
            id="BULK_PAYMENTS_FOOTER_IN_INVOICES"
            defaultMessage={`in ${selectedInvoicesLength} invoices`}
            values={{ selectedInvoicesLength }}
          />
        </span>
      </div>
      <Button
        buttonType="primary"
        width="301px"
        height="48px"
        size="jumbo"
        onClick={handleBulkCheckout}
      >
        <div className="button-content">
          <Lock color={colors.white} strokeWidth={2} height={20} width={20} className="lock-icon" />
          <FormattedMessage id="BULK_PAYMENTS_CHECKOUT" defaultMessage="Continue to checkout" />
        </div>
      </Button>
    </div>
    <style jsx>{`
      .sticky-footer-wrapper {
        position: sticky;
        bottom: 0;
        background: ${colors.offWhite};
        border-top: 1px solid ${colors.sidewalkGray};
        height: 98px;
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
      }
      .footer-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding: 24px;
        gap: 25px;
      }
      .footer-text {
        padding-left: 12px;
        color: ${colors.gray};
        font-family: AvenirNextforINTUIT-Demi;
        max-width: 230px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }
      .total-second-part {
        display: block;
        margin-left: 4px;
      }
      .button-content {
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: center;
      }
    `}</style>
  </div>
);
