import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import NewLabel from 'components/Shared/Icons/NewLabel';
import Checkbox from 'components/Shared/Inputs/Checkbox';
import { reformatDateToDDMMYY } from 'shared/dates';
import { colors, fontSize } from 'styles/cp';
import { UnpaidInvoice } from 'types/Insight';

interface BulkInvoiceRowProps {
  invoiceData: UnpaidInvoice;
  setBulkInvoices: React.Dispatch<React.SetStateAction<UnpaidInvoice[]>>;
  checked?: boolean;
  disabled?: boolean;
  currency?: string;
}

export const BulkInvoiceRow = ({
  invoiceData,
  setBulkInvoices,
  disabled = false,
  currency,
}: BulkInvoiceRowProps) => {
  const { dueDate, selected, docNumber, balance, link, partiallyPaid, overDue, paymentMethods } =
    invoiceData;

  const formattedDate = reformatDateToDDMMYY(dueDate);

  const isBadgeShown = partiallyPaid || overDue;

  const paymentMethodMap: Record<string, string> = {
    ACH: 'Bank',
    CC: 'Card',
  };
  const renderPaymentMethods = (paymentMethods: string[]) =>
    paymentMethods.map((method) => paymentMethodMap[method] || method).join(', ');

  const handleCheckboxChange = () => {
    setBulkInvoices((prev) => {
      const newInvoices = [...prev];
      const selectedInvoice = newInvoices.find((invoice) => docNumber === invoice.docNumber);
      if (!selectedInvoice) return newInvoices;
      selectedInvoice.selected = !selectedInvoice.selected;
      return newInvoices;
    });
  };

  return (
    <div className="invoice-row-wrapper">
      <div className="checkbox-wrapper">
        <Checkbox checked={selected ?? false} onChange={handleCheckboxChange} disabled={disabled} />
      </div>
      <div className="invoice-number-wrapper">
        <div className="section-wrapper">
          <div className="title">No.</div>
          <a className="info-text link-text" href={link} target="_blank" rel="noreferrer">
            {docNumber}
          </a>
        </div>
      </div>
      <div className="section-wrapper">
        <div className="title">
          <FormattedMessage id="BULK_PAYMENTS_AMOUNT" defaultMessage="Amount" />
        </div>
        <div className="info-text">
          <FormattedNumber
            style="currency"
            currency={currency}
            value={balance}
            maximumFractionDigits={2}
          />
          {partiallyPaid && (
            <NewLabel color={colors.gray02} width={68} height={16} label="PART PAID" boldText />
          )}
        </div>
      </div>
      <div className="section-wrapper">
        <div className="title">
          <FormattedMessage id="INVOICE_SUMMARY_DUE_DATE" defaultMessage="Due date" />
        </div>
        <div className="info-text">
          {formattedDate}
          {overDue && (
            <NewLabel color={colors.orangeWarn2} width={66} height={16} label="OVERDUE" boldText />
          )}
        </div>
      </div>
      <div className="section-wrapper">
        <div className="title">
          <FormattedMessage id="BULK_PAYMENTS_PAYABLE_BY" defaultMessage="Payable by" />
        </div>
        <div className="info-text">{renderPaymentMethods(paymentMethods)}</div>
      </div>
      <style jsx>{`
        .invoice-row-wrapper {
          display: flex;
          width: 100%;
          height: ${isBadgeShown ? 76 : 60}px;
          background-color: ${colors.white};
          border-radius: 8px;
          padding: 10px;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;
          align-items: flex-start;
          position: relative;
        }
        .checkbox-wrapper {
          position: absolute;
          top: ${isBadgeShown ? 28 : 20}px;
        }
        .invoice-number-wrapper {
          display: flex;
          gap: 12px;
          align-items: center;
          padding-left: 34px;
        }
        .section-wrapper {
          display: flex;
          flex-direction: column;
          text-align: left;
          min-width: 90px;
          max-width: 100px;
        }
        .title {
          font-size: ${fontSize.xxs};
          color: ${colors.gray03};
        }
        .info-text {
          font-size: ${fontSize.xs};
          color: ${colors.gray};
          display: flex;
          flex-direction: column;
          min-width: 90px;
          max-width: 100px;
          overflow: hidden;
          display: inline-block;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .link-text {
          color: ${colors.blue05};
          text-decoration: none;
        }
      `}</style>
    </div>
  );
};
